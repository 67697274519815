<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';

import api from '@/services/api';
import toast from '@/services/toast';
import useModalFeatures from '@/composables/useModalFeatures';
import useTrackChanges from '@/composables/useTrackChanges';
import { AppButton, AppCloseButton, FormInput, FormLabel, FormSwitch } from '@/components';
import { ISalaryCodeRequest, ISalaryCodeResource, SalarySystem } from '@/types/Salary';

type Props = {
  salaryCode: null | ISalaryCodeResource;
  system: SalarySystem;
};

const { salaryCode, system } = defineProps<Props>();

const emit = defineEmits<{
  cancel: [];
  created: [salaryCode: ISalaryCodeResource];
  updated: [salaryCode: ISalaryCodeResource];
  closed: [];
}>();

const { t } = useI18n({ useScope: 'global' });
const { onCtrlEnter } = useModalFeatures();

const loading = ref(false);

const form = reactive<ISalaryCodeRequest>({
  name: null,
  system_id: null,
  type: null,
  absence_type: null,
  is_default: false,
  client_work_time: false,
});

const tracker = useTrackChanges(form);

const disabled = computed(() => {
  /* Visma now not require absense type
  if (system === SalarySystem.VISMA && !form.absence_type) {
    return false;
  }*/
  return !(form.name && form.system_id && form.type);
});

async function submit() {
  loading.value = true;
  try {
    if (salaryCode) {
      const response = await api.salary.codes.update(salaryCode.id, form);
      emit('updated', response.data);
      toast.success(t('common.messages.has_been_updated', { name: response.data.name }));
    } else {
      const response = await api.salary.codes.store(form);
      emit('created', response.data);
      toast.success(t('common.messages.has_been_created', { name: response.data.name }));
    }
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

onMounted(async () => {
  form.name = salaryCode?.name ?? null;
  form.system_id = salaryCode?.system_id ?? null;
  form.type = salaryCode?.type ?? null;
  form.absence_type = salaryCode?.absence_type ?? null;
  form.is_default = salaryCode?.is_default ?? false;
  form.client_work_time = salaryCode?.client_work_time ?? false;
  tracker.commit();
});

onCtrlEnter(() => {
  if (!disabled.value) {
    if (tracker.isModified.value) {
      submit();
    } else {
      emit('cancel');
    }
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <form @submit.prevent="submit" @keydown.enter.prevent>
      <div class="modal-header">
        <h2 v-text="salaryCode ? t('salary.codes.edit.title') : t('salary.codes.create.title')" />
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <!-- Name -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="name" required>{{ t('salary.codes.attributes.name') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.name" id="name" required />
            </div>
          </div>
        </div>

        <!-- System ID -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="system_id" required>{{ t('salary.codes.attributes.system_id') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.system_id" id="system_id" required />
            </div>
          </div>
        </div>

        <!-- Type -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="type" required>{{ t('salary.codes.attributes.type') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.type" id="type" required />
            </div>
          </div>
        </div>

        <!-- Absence type -->

        <!--        <div class="form-group" v-if="system === SalarySystem.VISMA">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="absence_type" required>{{ t('salary.codes.attributes.absence_type') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.absence_type" id="absence_type" required />
            </div>
          </div>
        </div>-->

        <!-- Default -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="is_default">{{ t('salary.codes.attributes.is_default') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormSwitch id="is_default" v-model="form.is_default" />
            </div>
          </div>
        </div>

        <!-- Client work time -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="client_work_time">
                {{ t('salary.codes.attributes.client_work_time') }}
              </FormLabel>
            </div>
            <div class="col-md-9">
              <FormSwitch id="client_work_time" v-model="form.client_work_time" />
            </div>
          </div>
        </div>

        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="close" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton
            v-if="salaryCode"
            class="mt-2 mt-sm-0"
            color="success"
            :loading="loading"
            :disabled="disabled || !tracker.isModified.value"
          >
            {{ t('common.update') }}
          </AppButton>
          <AppButton v-else class="mt-2 mt-sm-0" color="secondary" :loading="loading" :disabled="disabled">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
