import { IOfficeResource } from '@/types/Office';
import { IDepartmentResource } from '@/types/Department';
import { IWorkTitleResource } from '@/types/WorkTitle';
import { SystemId } from '@/types/Common';

export enum UserStatusType {
  Invited = 'invited',
  Active = 'active',
  Blocked = 'blocked',
}

export enum UserRole {
  SuperAdmin = 'super_admin',
  CustomerAdmin = 'customer_admin',
  User = 'user',
}

export enum UserSystemRole {
  Manager = 'manager',
}

export enum UserPriceType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export interface IUserPriceChange {
  id: number;
  price: number;
  apply_date: string;
  type: UserPriceType;
  deletable: boolean;
}

export interface IUserWorkRate {
  id: number;
  rate: number;
  date_start: string;
}

export interface IUserCustomer {
  name: string;
  uuid: string;
  systems: SystemId[];
}

export interface IUserListResource {
  uuid: string;
  name: string;
  email: string;
  phone_number: null | string;
  role: UserRole;
  system_role: null | UserSystemRole;
  customer: IUserCustomer;
  logo: null | string;
  work_title: null | IWorkTitleResource;
  office: null | IOfficeResource;
  department: null | IDepartmentResource;
  manager: null | IUserPreviewResource;
  internal_cost: null | number;
  work_rate_percent: number;
  meta: boolean;
}

export interface IUserPreviewResource {
  uuid: string;
  name: string;
}

export interface IUserResource {
  uuid: string;
  name: string;
  personal_number: string;
  employee_number: string;
  office: null | IOfficeResource;
  department: null | IDepartmentResource;
  work_title: null | IWorkTitleResource;
  manager: null | IUserPreviewResource;
  hourly_price: null | number;
  internal_cost: null | number;
  internal_price_changes: IUserPriceChange[];
  external_price_changes: IUserPriceChange[];
  rate: null | IUserWorkRate;
  work_rate_percent: number;
  email: string;
  phone_number: null | string;
  status: UserStatusType;
  role: UserRole;
  system_role: null | UserSystemRole;
  customer: IUserCustomer;
  time_balance: number;
  logo: {
    dark: null | string;
    light: null | string;
  };
}

export interface IUserPriceChangeRequest {
  price: null | number;
  apply_date: null | string;
}

export interface IUserUpdateRequest {
  office_id: null | number;
  department_id: null | number;
  manager_uuid: null | string;
  work_title_id: null | number;
  employee_number: string;
  hourly_price?: IUserPriceChangeRequest;
  internal_cost?: IUserPriceChangeRequest;
}

export interface IUserWorkingDays {
  [key: string]: {
    days: {
      [key: string]: {
        [key: string]: number;
      };
    };
    max_week_minutes: number;
  };
}

export type PortalUserStatusType = 'active' | 'not_invited' | 'invited' | 'inactive';

export interface IUserDeadlineEventResource {
  client_uuid: string;
  client_name: string;
  projects: {
    id: number;
    name: string;
    tasks: {
      id: number;
      name: string;
      done_at: null | string;
      sub_label: null | string;
    }[];
  }[];
}

export enum VehicleType {
  PRIVATE_CAR = 'private_car',
  COMPANY_CAR_DIESEL = 'company_car_diesel',
  COMPANY_CAR_PETROL = 'company_car_petrol',
  COMPANY_CAR_ELECTRIC = 'company_car_electric',
}

export interface IVehicleRequest {
  name: null | string;
  type: null | VehicleType;
}

export interface IVehicleResource {
  id: number;
  name: string;
  type: VehicleType;
  created_at: string;
  in_use: boolean;
}

export interface ITimeBalanceTransactionSearchRequest {
  only_manual: boolean;
}

export interface ITimeBalanceTransactionResource {
  id: number;
  created_at: string;
  sum: number;
  comment: null | string;
  balance_before: number;
  balance_after: number;
}

export interface ITimeBalanceTransactionRequest {
  sum: null | number;
  comment: null | string;
}
