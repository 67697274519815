export default {
  index: {
    title: 'Kunder',
    help: '',
    create: 'Skapa kund',
    filters: {
      name_or_number: 'Kundnamn eller nummer',
      industries: 'Bransch (SNI)',
      group_tag: 'Tagg',
      city: 'Ort',
      fiscal_year_end: 'Bokslutsdatun',
      service_organisation: 'Samarbetspartner',
      service_organisation_contact: 'Extern kontakt',
      services: 'Tjänster',
      project_name: 'Projekt',
      team_member: 'Team-medlem',
      office: 'Kontor',
      show_all: 'Visa alla',
      show_only_active: 'Visa endast aktiva',
      show_connected_companies: 'Visa kopplade företag',
    },
  },
  create: {
    title: 'Kund',
    help: '',
  },
  edit: {
    title: 'Redigera kund: {name} ({org_number})',
    help: '',
  },
  service_company: {
    title: 'Samarbetspartners',
    add: {
      title: 'Länka samarbetspartner',
      service_company: 'Samarbetspartner',
    },
    tooltip: {
      detach: 'Avlänka {name} från kunden',
    },
    confirm: {
      detach: {
        title: 'Avlänka samarbetspartner',
        text: 'Är du säker att du vill avlänka <strong>{name}</strong> från kunden?',
      },
    },
    contact: {
      title: 'Kontakter',
      empty: 'Inga kontakter för samarbetspartner',
      add: {
        title: 'Länka kontakt',
        contact: 'Kontakt',
      },
      attributes: {
        account_in_portal: 'Tillgång till kundportal',
        portal_status: 'Portal-status',
      },
      tooltip: {
        attach: 'Länka ny kontakt',
        detach: 'Avlänka {name} från samarbetspartner',
      },
      confirm: {
        detach: {
          title: 'Avlänka kontakt',
          text: 'Är du säker att du vill avlänka <strong>{name}</strong> från <strong>{company}</strong> samarbetspartner?',
        },
      },
    },
  },
  projects: {
    index: {
      title: 'Kundprojekt: {name} ({org_number})',
      create: 'Skapa projekt',
      back: 'Alla projekt på kunden',
    },
  },
  form: {
    general_info: 'Allmän info',
    search_client: 'Sök kund',
    client_type: 'Kundtyp',
    company: 'Organisation',
    individual: 'Individ',
    client_company: 'Namn eller OrgNr',
    client_individual: 'Namn eller personnummer',
    registered_address: 'Adress',
    invoice_address: 'Fakturaadress',
    invoice_settings: 'Fakturainställningar',
    info_and_settings: 'Info & inställningar',
    customer_info: 'Kundinfo',
    customer_and_project_settings: 'Kund & projektinställningar',
    audit_service_info: 'Revisions-inställningar',
  },

  empty: 'Inga kunder. Använd filter för att visa kunder.',

  // Attributes
  attributes: {
    name: 'Namn',
    industry: 'Bransch (SNI)',
    group_tag: 'Tagg',
    service_companies: 'Samarbetspartner',
    service_company_contacts: 'Extern kontakt',
    active_services: 'Tjänster',
    active_projects: 'Projekt',
    project_responsible: 'Projektansvarig',
    office: 'Kontor',
    org_number: 'OrgNr',
    vat: 'MomsNr',
    company_category: 'Organisationsform',
    main_phone: 'Tele',
    responsible_user: 'Kundansvarig',
    last_financial_year: 'Aktuellt räkenskapsår',
    start_financial_year_date: 'Start räkenskapsår',
    end_financial_year_date: 'Slut räkenskapsår',
    first_address: 'Adress 1',
    second_address: 'Adress 2',
    zip: 'Postkod',
    city: 'Ort',
    country: 'Land',
    invoice_email: 'Fakturaadress Epost',
    invoice_default_contact_uuid: 'Kundens referens',
    invoice_grouping: 'Fakturagruppering',
    invoice_default_language: 'Fakturaspråk',
    invoice_default_info: 'Annan fakturainfo',
    invoice_distribution: 'Distributionstyp',
    r: 'R',
    e: 'E',
    b: 'B',
    p: 'P',
    reported: 'Rapporterad',
    estimated: 'Estimerad',
    budgeted: 'Budgeterad',
    planned: 'Planerad',
    marginal: 'Marg',
    other_info: 'Övrig info',
    specific_procedures: 'Särskilda rutiner',
    service_or_project: 'Service / Projekt',
    tags: 'Taggar',
    large_company: 'Stort företag?',
    has_formal_board_meetings: 'Har protokollförda styrelsemöten',
    prepares_financial_budgets: 'Kunden använder budget',
    registered_as_employer: 'Registrerad som arbetsgivare',
    registered_for_vat: 'Registrerad för moms',
    vat_reporting_dates: 'Redovisningsdatum för moms',
    registered_for_f_tax: 'F-skatt',
    part_of_company_group: 'Ingår i koncern',
    holding_company: 'Holdingbolag',
    consolidates_group_financial_statement: 'Upprättar koncernredovisning',
    dormant_limited_operations: 'Vilande/Begränsad verksamhet',
    customer_status: 'Kundstatus',
    use_we_connect: 'Använd WeConnect kundportal',
    insourced_services: 'Uppdraget görs hos kund',
    first_year_audit: 'Förstagångsrevision',
    audited_by_another_firm_previous_year: 'Föregående år reviderades av annan revisionsbyrå',
  },

  invoice_grouping: {
    without_grouping: 'Per projekt',
    by_project_responsible: 'Per projektansvarig',
    by_client_responsible: 'Per kundansvarig',
  },

  // Tooltips
  tooltip: {
    edit: 'Redigera "{name}" kund',
    destroy: 'Radera "{name}" kund',
    projects: 'Visa "{name}" kundprojekt',
  },

  contact: {
    search_in_db_or_cs: 'Sök befintlig eller ny kontakt',
    search_contacts_placeholder: 'Skriv namn eller personnummer för att hitta kontakt',
    existing: 'Befintlig',
    new: 'Ny',
    title: 'Kontakter',
    name: 'Namn',
    short_name: 'För- och efternamn',
    personal_number: 'Personnummer',
    responsible_user: 'Kundansvarig',
    email: 'Email',
    phone: 'Tele',
    roles: 'Roller',
    nationality: 'Nationalitet',
    comment: 'Kommentar',
    subscription_list: 'Nyhetsbrev',
    owner: 'Ägare',
    access_to_portal: 'Tillgång till kundportal',
    portal_status: 'Portal status',
    add: 'Lägg till',
    create: 'Skapa',
    sync: 'Synka',
    empty: 'Inga kontakter',
    modal: {
      title: 'Lägg till kontakt',
      edit_title: 'Redigera kontakt: {name}',
      create_role_name: 'Skapa ny roll: {name}',
    },
    tooltip: {
      edit: 'Redigera "{name}"',
      destroy: 'Radera "{name}"',
      activate_we_connect: 'Aktivera WeConnect kundportal för denna kunden för att bjuda in kontaktperson',
    },
    confirm: {
      delete: {
        title: 'Radera kontakt',
        text: 'Är du säker på att du vill radera kontakten <strong>{name}</strong>?',
      },
    },
  },

  overview: {
    invoice_credit_info: 'Faktura och kreditinfo',
    download_credit_report: 'Hämta kreditupplysning',
    due: 'Förfallna fakturor',
    credit_rating: 'Kreditrating',
    skulsaldo: 'Skulsaldo hos KFM',
    payment_remarks: 'Betalningsanmärkningar',
    registered_information: 'Registered information',
    general_information: 'Allmän information',
    status: 'Status',
    incorporation_date: 'Registreringsdatum',
    sni: 'SNI',
    business_description: 'Verksamhetsbeskrivning',
    share_capital: 'Aktiekapital',
    tax_registration: 'Skatteregistrering',
    tax: 'Skatt',
    f_tax: 'F-skatt',
    vat: 'Moms',
    employer: 'Arbetsgivare',
    group_information: 'Koncerninformation',
    parent_company: 'Moderbolag',
    group_parent_company: 'Moderbolag gruppen',
    signing_authority: 'Firmateckning',
    active: 'Aktiv',
    reg_date: 'Registreringsdatum',
    n_a: 'e/t',
    show_all_contacts: 'Visa alla kontakter',
    yes: 'Ja',
    no: 'Nej',
  },

  planning: {
    weekly_overview: 'Veckoöversikt',
    three_months: '3 månader',
    one_month: '1 månad',
    planned_elements_per_week: 'Antal per vecka',
    sum: 'Total',
    tracked_time: 'Registreradtid',
    event_week: 'Vecka',
    status: 'Status',
    week_one_letter: 'v',
    week: 'Vecka',
  },

  project: {
    title: 'Projektöversikt',
    show_only_active: 'Visa endast aktiva',
    attributes: {
      name: 'Projekt',
      start_date: 'Startdatum',
      end_date: 'Slutdatum',
      financial_year: 'Räkenskapsår',
      deadline: 'Deadline',
      status: 'Status',
      status_changed_at: 'status ändrad datum',
      time_reported: 'Rapporterad tid',
      hourly_avg_revenue: 'Arvode/h',
      margin: 'Marg',
      margin_percent: 'Marg%',
      notes: 'Noteringar',
      project_plan: 'Projektplan',
      review: 'Granska',
      project_notes: 'Projektnoteringar',
      actions: 'Åtgärder',
    },
  },
  reported_time: {
    title: 'Rapporterad tid',
    attributes: {
      project: 'Projekt',
      project_name: 'Projektnamn',
      task: 'Uppgift',
      task_name: 'Uppgiftsnamn',
      from_date: 'Från datum',
      to_date: 'Till datum',
      employee: 'Anställd',
      service: 'Tjänst',
      week: 'Vecka',
      estimated_time: 'Estimerad tid',
      reported_time: 'Rapporterad tid',
      billable_over_budget: 'Fakturerbar mertid',
      not_billable_over_budget: 'Bortskriven mertid',
      task_status: 'Status',
      task_note: 'Notering på aktivitet',
      time_report_notes: 'Notering i tidkort',
      billing_notes: 'Faktureringskommentar',
      time_report_comments: 'Kommentar på tidrapport',
      total_filtered: 'Summa (filtrerad)',
      total_by_employee: 'Summa per anställd',
      view_per_page: 'Visa per sida',
    },
    empty: 'Inga sökresultat',
  },

  billing: {
    title: 'Faktureringsöversikt',
    attributes: {
      scheduled: 'Schemalagda och skapade fakturor',
      total: 'Summa',
      show_inactive_projects: 'Visa inaktiva projekt',
      show_my_responsible_only: 'Visa endast mina',
      create_empty_invoice: 'Skapa tom faktura',
      create_empty_invoice_text:
        'Detta skapar en faktura som inte är kopplat till riktpris eller rapporterade timmar. Lägg till fakturarader och koppla till projekt i det nya fönstret.',
      create_new_periodical_invoice: 'Skapa new periodisk faktura',
    },
    invoice_until_today: 'Fakturera fram till idag',
    invoice_until_today_tooltip:
      'Detta skapar en faktura för denna kund eller projekt med alla priser, utlägg med mera fram till dagens datum.',
    invoice_overview: 'Fakturaöversikt',
    project_overview: 'Projektöversikt',
    invoiced: 'Fakturerat',
    postponed: 'Framflyttat',
    written_down: 'Nedskrivet',
    fees: {
      base_fee: 'Grundarvode',
      separate_fee: 'Separat arvode',
      hourly_fee: 'Timarvode',
    },
    forecast: 'Prognos',
    forecast_tooltip:
      'Prognostiserade arovden är ej Schemalagda arvoden som beräknats, med antagabde att projektet kommer att förnyas efter dess slutdatum.',
    total_all_projects: 'Summa alla projekt',
  },

  tabs: {
    dashboard: 'Översikt',
    planning: 'Planering',
    invoicing: 'Fakturering',
    billing: 'Fakturering',
    project: 'Projekt',
    checkpoints: 'Checkpoints',
    reported_time: 'Rapporterad tid',
    purchases: 'Inköp',
    customer_folders: 'Filer',
    templates: 'Mallar',
    processes: 'Processer',
    settings: 'Inställningar',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Radera kund',
      text: 'Är du säker på att du vill radera <strong>{name} ({org_number})</strong>?',
    },
    report_download: {
      title: 'Vill du fortsätta?',
      text: 'Kreditupplysning kommer att skapa en omfrågandekopia till denna person. Är du säker?',
    },
  },

  processes: {
    general_process_information: 'Allmän processinformation',
    general_it_information: 'Allmän IT-information',
    it_access_and_permissions: 'IT access och behörigheter',
    process_descriptions: 'Processbeskrivningar',
    accounting_and_general_ledger: 'Bokföring och huvudbok',
    salary_fringe_benefits_and_tax: 'Lön, förmåner och skatt',
    supplier_invoices: 'Leverantörsfakturor (inköp)',
    receipts_and_travel_expense_reports: 'Kvitton (inköp) & reseräkning',
    customer_invoices_and_collection: 'Kundfakturor och fordringsbevakning',
    project_reporting_and_work_in_progress: 'Projektrapporterng & pågående arbeten',
    accruals_and_prepayments: 'Periodiseringar',
    bank_and_payments: 'Bank & betalningar',
    loans_and_other_credits: 'Lån & andra krediter',
    cash_register_and_daily_sales: 'Kassaregister & försäljning',
    inventory: 'Lager',
    real_estate: 'Fastigheter',
    fixed_assets: 'Anläggningstillgångar',
    activates_internally_developed_assets: 'Aktivering intern utvecklade tillgångar',
    securities_trading: 'Handel med värdepapper',
    suspense_account_and_missing_documents: 'OBS-konto & saknade underlag',
    annual_closing_and_financial_statement: 'Årsbokslut & årsredovisning',
    income_tax_return: 'Inkomstdeklaration',
    vat: 'Moms',
    management_reporting: 'Intern ekonomirapportering',
    archiving_and_backup: 'Arkivering & Backup',
    // attributes
    framework: 'Redovisningsramverk',
    system: 'System',
    we_do: 'Görs av oss',
    deadline: 'Deadline',
    reporting_deadline: 'Rapporterings-deadline',
    reporting_frequency: 'Rapporteringsfrekvens',
    periodic_summary: 'Periodisk sammanställning',
    description: 'Beskrivning',
    vat_reporting_frequency: {
      annually: 'Ett beskattningsår',
      quarterly: 'Kalenderkvartal ',
      monthly: 'Månatligen',
    },
    accounting_and_general_ledger_framework: {
      k1: 'K1: förenklat årsbokslut',
      k2: 'K2',
      k3_less: 'K3 - Mindre företag',
      k3_bigger: 'K3 - Större företag',
      ifrs: 'IFRS',
      arkl: 'ÅRKL',
      other: 'Övrigt',
    },
    vat_reporting_deadline: {
      '26_of_second_month_following_period': 'Den 26 i andra månaden efter redovisningsperioden',
      submitted_separately:
        'Momsdeklarationen lämnas separat i nära anslutning till inkomst deklarationen** (ej vid handel med företag i andra EU-länder)',
      '12_of_second_month_following_period': 'Den 12 i andra månaden efter redovisningsperioden',
      '12_of_second_month_after_period':
        'Den 12 i andra månaden efter redovisningsperioden (beskattningsunderlaget är högst 40 miljoner kr)',
      '26_of_first_month_after_period':
        'Den 26 i första månaden efter redovisningsperioden (måste användas när beskattningsunderlaget överstiger 40 miljoner kr)',
    },
  },

  statuses: {
    prospect: 'Prospekt',
    blacklist: 'Svartlistad',
    active: 'Aktiv',
    inactive: 'Inaktiv',
  },

  tags: {
    modal: {
      create: {
        title: 'Lägg till ny tagg',
      },
    },
    attributes: {
      name: 'Tagg-namn',
    },
  },

  it_systems: {
    modal: {
      create: {
        title: 'Lägg till nytt system',
      },
    },
    attributes: {
      name: 'Systemnamn',
    },
  },
};
