<script setup lang="ts">
import { watch, ref, onMounted, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouteQuery } from '@vueuse/router';
import { useModal } from 'vue-final-modal';

import api from '@/services/api';
import {
  AppLoader,
  AppButton,
  AppAlert,
  FontIcon,
  AppTable,
  AppTableHead,
  AppTableBody,
  AppTableTr,
  AppTableTd,
  AppTableTh,
  AppPagination,
  ConfirmModal,
  TimePeriodStatus as TimePeriodStatusComponent,
  HelpInformation,
} from '@/components';
import { ITimePeriodResource, TimePeriodStatus } from '@/types/TimePeriod';
import useLoader from '@/composables/useLoader';
import useAuthStore from '@/store/AuthStore';
import route from 'ziggy-js';
import { useTitle } from '@vueuse/core';

const currentPage = useRouteQuery('page', '1', { transform: Number });
const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { isCustomerAdminRole, isSuperAdminRole, isManagerRole, isUserRole } = useAuthStore();

const perPage = ref(20);
const total = ref(0);

const timePeriods = ref<ITimePeriodResource[]>([]);

const editable = computed(() => isCustomerAdminRole || isSuperAdminRole || (isManagerRole && !isUserRole));

const changeStatusModal = useModal({
  component: ConfirmModal,
  attrs: {
    title: t('time-period.confirm.change_status.title'),
    async onConfirm() {
      try {
        // @ts-ignore
        changeStatusModal.patchOptions({ attrs: { loading: true } });
        const id = changeStatusModal.options.attrs?.params?.id as number;
        const status = changeStatusModal.options.attrs?.params?.status as TimePeriodStatus;
        await api.timePeriods.update(id, { status });
        await getTimePeriods();
        await changeStatusModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        // @ts-ignore
        changeStatusModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      changeStatusModal.close();
    },
    // @ts-ignore
    onClosed() {
      // @ts-ignore
      changeStatusModal.patchOptions({ attrs: { loading: false } });
    },
  },
});

async function getTimePeriods() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());
    const response = await api.timePeriods.index({ searchParams });
    timePeriods.value = response.data;
    perPage.value = response.meta!.per_page;
    total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  }
}

async function onChangeStatus(timePeriod: ITimePeriodResource) {
  changeStatusModal.patchOptions({
    // @ts-ignore
    attrs: {
      params: {
        id: timePeriod.id,
        status: timePeriod.status === 'opened' ? 'closed' : 'opened',
      },
      message: t(`time-period.confirm.change_status.${timePeriod.status}`),
    },
  });
  await changeStatusModal.open();
}

onMounted(async () => {
  loader.start();
  await getTimePeriods();
  loader.finish();
});

watch(currentPage, async () => {
  loader.start();
  await getTimePeriods();
  loader.finish();
});

const title = useTitle(computed(() => t('time-period.index.title')));
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <div class="container-fluid" v-else>
    <div class="d-flex align-items-end mb-3">
      <h1 class="mb-0" v-text="title" />
      <HelpInformation class="ml-1" translation="time-period.index.help" />
    </div>
    <AppAlert v-if="timePeriods.length === 0">{{ t('time-period.empty') }}</AppAlert>
    <AppTable v-else hoverable>
      <AppTableHead>
        <AppTableTr>
          <AppTableTh nowrap>{{ t('time-period.attributes.month') }}-{{ t('time-period.attributes.year') }}</AppTableTh>
          <AppTableTh nowrap>{{ t('time-period.attributes.status') }}</AppTableTh>
          <AppTableTh nowrap class="text-right" v-if="editable">{{ t('common.actions') }}</AppTableTh>
          <AppTableTh nowrap></AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody>
        <AppTableTr v-for="timePeriod in timePeriods" :key="timePeriod.id">
          <AppTableTd nowrap> {{ timePeriod.month.toString().padStart(2, '0') }}-{{ timePeriod.year }} </AppTableTd>
          <AppTableTd><TimePeriodStatusComponent :status="timePeriod.status" /></AppTableTd>
          <AppTableTd nowrap class="text-right" v-if="editable">
            <AppButton
              v-tooltip="
                timePeriod.status === 'opened' ? t('time-period.tooltip.close') : t('time-period.tooltip.open')
              "
              @click.stop.prevent="onChangeStatus(timePeriod)"
              size="small"
              light
              circle
              :color="timePeriod.status === 'opened' ? 'danger' : 'success'"
            >
              <FontIcon v-if="timePeriod.status === 'opened'" name="ban" />
              <FontIcon v-else name="player-play" />
            </AppButton>
          </AppTableTd>
          <AppTableTd>
            <a
              v-if="timePeriod.status === 'closed'"
              :href="
                route('time-periods.get_salary_export', {
                  yearMonth: timePeriod.year + timePeriod.month.toString().padStart(2, '0'),
                  salarySystemType: 'HogiaLon',
                })
              "
              >Hogia</a
            >
            <span style="margin-left: 10px"> </span>
            <a
              v-if="timePeriod.status === 'closed'"
              :href="
                route('time-periods.get_salary_export', {
                  yearMonth: timePeriod.year + timePeriod.month.toString().padStart(2, '0'),
                  salarySystemType: 'VismaLon',
                })
              "
              >Visma</a
            >
          </AppTableTd>
        </AppTableTr>
      </AppTableBody>
    </AppTable>
    <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
  </div>
</template>
