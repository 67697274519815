<script setup lang="ts">
import { computed, inject, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { nanoid } from 'nanoid';
import VueDatePicker from '@vuepic/vue-datepicker';
import { useModal } from 'vue-final-modal';
import { storeToRefs } from 'pinia';
import { debounce } from 'perfect-debounce';
import draggable from 'vuedraggable';
import { klona } from 'klona';
import { IconCircleCheck, IconClockEdit, IconReceipt } from '@tabler/icons-vue';
import useLoader from '@/composables/useLoader';
import useDate from '@/composables/useDate';
import useTime from '@/composables/useTime';
import useTask from '@/composables/useTask';
import useProjectStore from '@/store/ProjectStore';
import useProject from '@/composables/useProject';
import {
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  FontIcon,
  FormMinutesDuration,
  FormSwitch,
  FormWeekPicker,
} from '@/components';
import api from '@/services/api';
import toast from '@/services/toast';
import { IProjectEstimates, IProjectTeamMemberListResource, ProjectStep } from '@/types/Project';
import { IServiceTaskResource } from '@/types/Service';
import {
  IPlannedProjectTaskResource,
  IPlanningEventView,
  IProjectTaskPlanningView,
  IProjectTaskResource,
  ProjectTaskPriceType,
  ProjectTaskType,
} from '@/types/ProjectTask';
import { ConfirmDialogConfirmParams, ProjectUserRole } from '@/types/Common';
import useProjectPlanning from '@/composables/useProjectPlanning';

const projectId = inject<number>('projectId') as number;
const clientUuid = inject<string>('clientUuid') as string;

const { calculateAverageCost } = useProject();

const projectStore = useProjectStore();
const { setProject, lockTabs, unlockTabs } = projectStore;
const { project, isDraft, isActive } = storeToRefs(projectStore);

const { t, n, locale } = useI18n({ useScope: 'global' });
const { taskFrequencyOptions } = useTask();
const { getFirstDayOfWeek, getLastDayOfWeek } = useDate();
const loader = useLoader({ useProgress: false });
const nextLoader = useLoader({ useProgress: false });
const saveLoader = useLoader({ useProgress: false });
const { convertMinutesToTime } = useTime();
const { createTaskTemplate, taskResourceToView, taskViewToSummaryEstimatesRequest, taskViewToGenerateEventRequest } =
  useProjectPlanning();

const teamMembers = ref<IProjectTeamMemberListResource[]>([]);
const serviceTasks = ref<IServiceTaskResource[]>([]);
const estimates = ref<null | IProjectEstimates>(null);

const projectShouldBeReactivated = computed(
  () => project.value && isActive.value && project.value.planned_tasks.length > 0,
);

const emit = defineEmits<{
  (e: 'change-step', step: ProjectStep): void;
}>();

const submitMode = ref<'back' | 'update' | 'next'>('next');

const tasks = ref<IProjectTaskPlanningView[]>([]);
const originalTasks = ref<IProjectTaskPlanningView[]>([]);
const changeProjectStep = ref(false);
const isSubLabelEnabled = ref(false);

async function getService() {
  try {
    const response = await api.services.get(project.value.service.uuid);
    serviceTasks.value = response.data.tasks;
  } catch (error) {
    console.error(error);
  }
}

async function submit() {
  lockTabs();
  if (submitMode.value === 'update') saveLoader.start();
  else if (submitMode.value === 'next') {
    nextLoader.start();
    changeProjectStep.value = true;
  }
  setTasksOrders();
  try {
    const tasksToSend = klona(tasks.value).map((task) => {
      if (isActive.value) {
        // Check if task was changed
        const originalTask = originalTasks.value.find(({ uid }) => uid === task.uid);
        if (originalTask) {
          if (task.is_planned === false) {
            if (
              originalTask.service_task_id !== task.service_task_id ||
              originalTask.task_week !== task.task_week ||
              originalTask.user_uuid !== task.user_uuid ||
              originalTask.default_frequency !== task.default_frequency ||
              originalTask.default_time_budget !== task.default_time_budget ||
              originalTask.custom_price !== task.custom_price ||
              originalTask.custom_price_type !== task.custom_price_type ||
              originalTask.custom_price_value !== task.custom_price_value ||
              task.is_deleted
            ) {
              task.parent_project_task_id = task.id;
              task.is_planned = true;
              task.id = undefined;

              // Mark each event as planned
              task.events = task.events.map((event) => ({
                ...event,
                parent_event_id: event.parent_event_id ?? event.id,
                is_planned: true,
                id: undefined,
                is_deleted: task.is_deleted,
              }));

              return task;
            }

            const atLeastOneEventChanged = task.events
              .filter((event) => event.id)
              .some((event) => {
                const originalEvent = originalTask.events.find(({ id }) => id === event.id)!;
                return (
                  event.week !== originalEvent.week ||
                  event.scheduled_time !== originalEvent.scheduled_time ||
                  event.deadline !== originalEvent.deadline ||
                  event.user_uuid !== originalEvent.user_uuid ||
                  event.is_deleted !== originalEvent.is_deleted
                );
              });
            if (atLeastOneEventChanged || task.events.length !== originalTask.events.length) {
              // Mark task as planned
              task.parent_project_task_id = task.id;
              task.is_planned = true;
              task.id = undefined;

              // Mark each event as planned
              task.events = task.events.map((event) => ({
                ...event,
                parent_event_id: event.id,
                is_planned: true,
                id: undefined,
                is_deleted: event.is_deleted,
              }));
            }
          } else {
            // If a task was restored then set is_deleted in true for all its events
            if (originalTask.is_deleted !== task.is_deleted && task.parent_project_task_id) {
              if (!task.is_deleted) {
                task.events.forEach((event) => {
                  event.is_deleted = false;
                  event.id = undefined;
                });
              }
            } else {
              task.events.forEach((event) => {
                if (event.is_invoiced || event.is_done || event.has_tracked_time) {
                  event.parent_event_id = event.parent_event_id ?? event.id;
                }
              });
            }
          }
        }
      }
      return task;
    });

    const response = await api.projects.tasks.update(clientUuid, projectId, {
      tasks: tasksToSend,
      change_project_step: changeProjectStep.value,
      is_sub_label_enabled: isSubLabelEnabled.value,
    });
    setProject(response.data);
    tasks.value = setTasks({
      tasks: response.data.tasks,
      planned_tasks: response.data.planned_tasks,
    });
    if (submitMode.value === 'next') {
      emit('change-step', ProjectStep.Activate);
    }
    if (submitMode.value === 'update') {
      toast.success(t('common.messages.has_been_updated', { name: t('common.project') }));
    }
  } catch (error) {
    console.error(error);
  } finally {
    nextLoader.finish();
    saveLoader.finish();
    changeProjectStep.value = false;
    unlockTabs();
  }
}

async function getTeamMembers() {
  try {
    const response = await api.projects.teamMember.list(clientUuid, projectId);
    teamMembers.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

function onTaskDelete(taskToDelete: IProjectTaskPlanningView) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('task.confirm.destroy.title'),
      message: t('task.confirm.destroy.text', { name: taskToDelete.name }),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          const task = tasks.value.find((t) => t.uid === taskToDelete.uid);
          if (task) {
            if (isActive.value && (task.is_planned === false || (task.is_planned && task.parent_project_task_id))) {
              task.is_deleted = true;
            } else {
              tasks.value = tasks.value.filter((task) => task.uid !== taskToDelete.uid);
              toast.success(t('common.messages.has_been_deleted', { name: t('common.task') }));
            }
            await getSummaryEstimates();
            await close();
            setTasksOrders();
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onTaskRestore(task: IProjectTaskPlanningView) {
  task.is_deleted = false;
  task.events.forEach((event) => {
    event.is_deleted = false;
  });
}

function onTaskEventDelete(task: IProjectTaskPlanningView, event: IPlanningEventView) {
  if (isActive.value && (task.is_planned === false || (task.is_planned && task.parent_project_task_id))) {
    event.is_deleted = true;
  } else {
    task.events = task.events.filter(({ uid }) => event.uid !== uid);
  }
}

function getAutoIncludesTasks() {
  const tasksToSet: IProjectTaskPlanningView[] = [];
  serviceTasks.value
    .sort((a, b) => a.order - b.order)
    .filter((task) => task.auto_include_in_planning)
    .forEach((task) => {
      let user_uuid: string | null = null;
      if (teamMembers.value.length === 1) {
        user_uuid = project.value.user.uuid;
      } else {
        switch (task.default_role) {
          case ProjectUserRole.TEAM_MEMBER:
            user_uuid = teamMembers.value.find((member) => member.uuid !== project.value.user.uuid)?.uuid || null;
            break;
          case ProjectUserRole.RESPONSIBLE:
            user_uuid = project.value.user.uuid || null;
            break;
          default:
            break;
        }
      }
      tasksToSet.push(
        createTaskTemplate({
          service_task_id: task.id,
          order: task.order,
          name: task.name,
          default_frequency: task.default_frequency,
          default_time_budget: task.default_time_budget,
          user_uuid,
          events: [],
        }),
      );
    });
  takeTasksSnapshot(tasksToSet);
  return tasksToSet;
}

function copyWeekToNextTasks(task: IProjectTaskPlanningView, taskIndex: number, many: boolean) {
  if (!task.task_week) return;
  if (many) {
    tasks.value.forEach((nextTask, nextIndex) => {
      if (nextIndex > taskIndex && task.task_week) {
        if (nextTask.is_deleted) return;
        if (nextTask.task_week !== task.task_week) {
          nextTask.task_week = task.task_week;
          checkTaskOnGenerationEvents(nextTask, false);
        }
      }
    });
    getSummaryEstimates();
    return;
  }
  if (tasks.value[taskIndex + 1].task_week !== task.task_week) {
    if (tasks.value[taskIndex + 1].is_deleted) return;
    tasks.value[taskIndex + 1].task_week = task.task_week;
    onTaskChangeForGenerationEvents(tasks.value[taskIndex + 1], false);
  }
}

function copyWeekToNextEvents(task: IProjectTaskPlanningView, eventIndex: number, many: boolean) {
  const { week } = task.events[eventIndex];
  if (!week) return;
  // Many
  if (many) {
    task.events.forEach((nextEvent, nextIndex) => {
      if (nextIndex > eventIndex) {
        if (nextEvent.is_deleted) return;
        if (nextEvent.is_done || nextEvent.is_invoiced) return;
        nextEvent.week = week;
      }
    });
    return;
  }
  // One
  const nextEvent = task.events[eventIndex + 1];
  if (nextEvent.is_done || nextEvent.is_invoiced || nextEvent.is_deleted) return;
  nextEvent.week = week;
}

function addBlankTask() {
  tasks.value.push(
    createTaskTemplate({
      order: tasks.value.length + 1,
      is_planned: isActive.value,
      user_uuid: teamMembers.value.length === 1 ? project.value.user.uuid : null,
    }),
  );
}

function addBlankEvent(task: IProjectTaskPlanningView) {
  task.events.push({
    uid: nanoid(),
    scheduled_time: task.default_time_budget ?? 0,
    user_uuid: task.user_uuid,
    week: task.task_week,
    is_done: false,
    is_invoiced: false,
    has_tracked_time: false,
    deadline: null,
  });
}

const summaryAbortController = ref<null | AbortController>(null);
async function getSummaryEstimates() {
  if (summaryAbortController.value) {
    summaryAbortController.value.abort('Abort fetching summary estimates.');
  }
  try {
    summaryAbortController.value = new AbortController();
    estimates.value = await api.projects.summaryEstimates(clientUuid, projectId, {
      signal: summaryAbortController.value.signal,
      json: {
        tasks: tasks.value
          .filter(
            (task) =>
              task.service_task_id &&
              task.task_week &&
              task.default_time_budget &&
              task.user_uuid &&
              task.events.every((event) => event.week && event.user_uuid),
          )
          .filter(({ is_deleted }) => !is_deleted)
          .map(taskViewToSummaryEstimatesRequest),
      },
    });
  } catch (error) {
    console.error(error);
  } finally {
    summaryAbortController.value = null;
  }
}

function setTasksOrders() {
  tasks.value.forEach((task, index) => {
    task.order = index + 1;
  });
}

function sortTasksByWeekAsc() {
  tasks.value.sort((a: IProjectTaskPlanningView, b: IProjectTaskPlanningView) => {
    if (a.task_week === null) return 1;
    if (b.task_week === null) return -1;
    return a.task_week - b.task_week;
  });
  setTasksOrders();
}

function setTasks({
  tasks,
  planned_tasks,
}: {
  tasks: IProjectTaskResource[];
  planned_tasks: IPlannedProjectTaskResource[];
}): IProjectTaskPlanningView[] {
  const tasksToSet: IProjectTaskPlanningView[] = [];
  // Hide tasks if they are in planned
  const plannedTasksIds = planned_tasks
    .filter(({ parent_project_task_id }) => Number.isInteger(parent_project_task_id))
    .map(({ parent_project_task_id }) => parent_project_task_id);

  // Add tasks
  tasks
    .filter((task) => !plannedTasksIds.includes(task.id))
    .forEach((task) => {
      tasksToSet.push({
        ...taskResourceToView(task),
        has_not_activated_changes: task.has_not_activated_changes,
        is_planned: false,
        is_deleted: false,
      });
    });

  // Add planned tasks
  planned_tasks.forEach((planned_task) => {
    tasksToSet.push({
      ...taskResourceToView(planned_task),
      parent_project_task_id: planned_task.parent_project_task_id,
      is_planned: true,
      is_deleted: planned_task.is_deleted,
    });
  });

  tasksToSet.sort((a: IProjectTaskPlanningView, b: IProjectTaskPlanningView) => {
    if (a.task_week === null) return 1;
    if (b.task_week === null) return -1;
    return a.task_week - b.task_week;
  });

  tasksToSet.forEach((task, index) => {
    task.order = index + 1;
  });

  takeTasksSnapshot(tasksToSet);
  return tasksToSet;
}

function takeTasksSnapshot(tasksToSet: IProjectTaskPlanningView[]) {
  originalTasks.value = klona(tasksToSet);
}

const onTaskServiceTaskChange = debounce(async (task: IProjectTaskPlanningView, index: number) => {
  const serviceTask = serviceTasks.value.find((serviceTask) => serviceTask.id === task.service_task_id);
  if (serviceTask) {
    tasks.value[index].default_frequency = serviceTask.default_frequency;
    tasks.value[index].default_time_budget = serviceTask.default_time_budget;
    tasks.value[index].name = serviceTask.name;

    const firstTeamMemberUuid = teamMembers.value.find((member) => member.uuid !== project.value.user.uuid)?.uuid || '';
    const projectResponsibleUuid = project.value.user.uuid || '';
    tasks.value[index].user_uuid =
      serviceTask.default_role === 'team_member' ? firstTeamMemberUuid : projectResponsibleUuid;
  }
  await checkTaskOnGenerationEvents(task, true);
  await getSummaryEstimates();
}, 500);

async function checkTaskOnGenerationEvents(task: IProjectTaskPlanningView, collapsed: boolean) {
  if (!task.service_task_id || !task.task_week || !task.user_uuid) return;
  lockTabs();
  try {
    task.loading = true;
    const events = await api.projects.tasks.generateEvents(
      clientUuid,
      projectId,
      task.service_task_id,
      taskViewToGenerateEventRequest(task),
    );
    task.events = events.map((event) => {
      if (event.id) {
        // @ts-ignore
        event.parent_event_id = event.id;
        delete event.id;
      }
      return {
        uid: nanoid(),
        ...event,
      };
    });
    task.collapsed = collapsed;
  } catch (error) {
    console.error(error);
  } finally {
    task.loading = false;
    unlockTabs();
  }
}

const onTaskChangeForGenerationEvents = debounce(async (task: IProjectTaskPlanningView, collapsed: boolean) => {
  await checkTaskOnGenerationEvents(task, collapsed);
  await getSummaryEstimates();
}, 500);

onMounted(async () => {
  lockTabs();
  loader.start();
  await Promise.all([getService(), getTeamMembers()]);
  if (project.value.tasks.length === 0 && isDraft.value) {
    tasks.value = getAutoIncludesTasks();
  } else {
    tasks.value = setTasks({
      tasks: project.value.tasks,
      planned_tasks: project.value.planned_tasks,
    });
  }
  isSubLabelEnabled.value = project.value.is_sub_label_enabled;
  await getSummaryEstimates();
  loader.finish();
  unlockTabs();
  tasks.value.forEach((task) => {
    if (task.user_uuid === null || task.events.some((event) => event.user_uuid === null)) {
      task.collapsed = true;
    }
  });
  watch(tasks, debounce(getSummaryEstimates, 500), { deep: true });
});
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <template v-else>
    <AppTable v-if="estimates" hoverable>
      <AppTableHead>
        <AppTableTr>
          <AppTableTh nowrap>{{ t('project.planning_table.summary') }}</AppTableTh>
          <AppTableTh nowrap class="text-right">{{ t('project.planning_table.total_time') }}</AppTableTh>
          <AppTableTh nowrap class="text-right">{{ t('project.planning_table.total_price') }}</AppTableTh>
          <AppTableTh nowrap class="text-right">{{ t('project.planning_table.margin') }}</AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody>
        <AppTableTr>
          <AppTableTd nowrap>
            <strong v-text="t('project.planning_table.this_project')" />
          </AppTableTd>
          <AppTableTd nowrap class="text-right">
            {{ convertMinutesToTime(estimates.current_project.total_minutes) }}
            ({{
              calculateAverageCost(
                estimates.current_project.external_total_price,
                estimates.current_project.total_minutes,
              )
            }}/{{ t('project.planning_table.hour') }})
          </AppTableTd>
          <AppTableTd nowrap class="text-right">
            {{ n(estimates.current_project.external_total_price, 'currency') }}
          </AppTableTd>
          <AppTableTd nowrap class="text-right"> {{ estimates.current_project.marginality_percent }} %</AppTableTd>
        </AppTableTr>
        <AppTableTr>
          <AppTableTd nowrap>
            <strong v-text="t('project.planning_table.next_project')" />
          </AppTableTd>
          <AppTableTd nowrap class="text-right">
            {{ convertMinutesToTime(estimates.next_project.total_minutes) }}
            ({{
              calculateAverageCost(estimates.next_project.external_total_price, estimates.next_project.total_minutes)
            }}/{{ t('project.planning_table.hour') }})
          </AppTableTd>
          <AppTableTd nowrap class="text-right">
            {{ n(estimates.next_project.external_total_price, 'currency') }}
          </AppTableTd>
          <AppTableTd nowrap class="text-right"> {{ estimates.next_project.marginality_percent }} %</AppTableTd>
        </AppTableTr>
      </AppTableBody>
    </AppTable>
    <form class="mt-3" @submit.prevent="submit">
      <AppTable class="tasks-table" hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap>{{ t('project.tasks_table.name') }}</AppTableTh>
            <AppTableTh nowrap v-if="isSubLabelEnabled">{{ t('project.tasks_table.sub_label') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.tasks_table.frequency') }}</AppTableTh>
            <AppTableTh nowrap>
              <span @click="sortTasksByWeekAsc" class="underline pointer" v-text="t('project.tasks_table.week')" />
            </AppTableTh>
            <AppTableTh nowrap>{{ t('project.tasks_table.deadline') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.tasks_table.estimated_time') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.tasks_table.team_member') }}</AppTableTh>
            <AppTableTh>{{ t('project.tasks_table.charge_separately') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.tasks_table.price_type') }}</AppTableTh>
            <AppTableTh nowrap>
              {{ t('project.tasks_table.amount') }} / <br />
              {{ t('project.tasks_table.discount') }}
            </AppTableTh>
            <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <draggable v-model="tasks" item-key="uid" tag="tbody" ghost-class="draggable-ghost" handle=".handle">
          <template #item="{ element: task, index }: { element: IProjectTaskPlanningView, index: number }">
            <AppTableTr :class="{ 'is-expanded': task.collapsed }" :loading="task.loading">
              <AppTableTd>
                <div class="d-flex align-items-center">
                  <i class="ti ti-grip-vertical text-2 text-neutral-400 flex-shrink-0 pr-2 handle grab" />
                  <i
                    class="ti text-2 flex-shrink-0 pr-2"
                    :class="{
                      'ti-calendar-event text-secondary-500': task.task_type === ProjectTaskType.BASE_PLAN,
                      'ti-calendar-plus text-success-500': task.task_type === ProjectTaskType.ADDITIONAL,
                    }"
                    v-tooltip.right="t(`project.tooltip.${task.task_type}_task_type`)"
                  />

                  <div class="form-wrapper is-small flex-grow-1">
                    <select
                      @change="onTaskServiceTaskChange(task, index)"
                      class="form-control"
                      v-model="task.service_task_id"
                      required
                      :disabled="task.is_deleted"
                      :class="{ 'is-invalid': !task.service_task_id }"
                      style="min-width: 100px"
                    >
                      <option :value="null" hidden disabled v-text="t('common.select')" />
                      <option
                        v-for="(option, optionIndex) in serviceTasks"
                        :key="optionIndex"
                        :value="option.id"
                        v-text="option.name"
                      />
                    </select>
                  </div>
                </div>
                <div class="mt-4" v-if="task.collapsed && task.default_frequency">
                  <div
                    style="height: 24px"
                    v-for="(event, eventIndex) in task.events"
                    :key="event.uid"
                    class="mt-2 ml-4"
                  >
                    Event {{ eventIndex + 1 }}
                  </div>
                </div>
              </AppTableTd>
              <AppTableTd v-if="isSubLabelEnabled">
                <div class="form-wrapper is-small">
                  <input v-model="task.sub_label" class="form-control" :disabled="task.is_deleted" />
                </div>
              </AppTableTd>
              <AppTableTd>
                <div class="form-wrapper is-small">
                  <select
                    @change="onTaskChangeForGenerationEvents(task, true)"
                    class="form-control"
                    v-model="task.default_frequency"
                    required
                    :disabled="task.is_deleted"
                    style="min-width: 100px"
                  >
                    <option
                      v-for="(option, optionIndex) in taskFrequencyOptions"
                      :key="optionIndex"
                      :value="option.value"
                      v-text="option.label"
                    />
                  </select>
                </div>
                <div class="mt-4" v-if="task.collapsed && task.default_frequency">
                  <div
                    style="height: var(--element-height-small)"
                    v-for="event in task.events"
                    :key="event.uid"
                    class="mt-2 task-status-icons"
                  >
                    <IconReceipt
                      v-if="event.is_invoiced"
                      style="color: var(--color-warning-500-hex)"
                      v-tooltip="t('event.event_is_invoiced')"
                      :size="24"
                      stroke-width="1.5"
                    />
                    <IconCircleCheck
                      v-else-if="event.is_done"
                      style="color: var(--color-success-500-hex)"
                      v-tooltip="t('event.event_is_done')"
                      :size="24"
                      stroke-width="1.5"
                    />
                    <IconClockEdit
                      v-else-if="event.has_tracked_time"
                      style="color: var(--color-secondary-500-hex)"
                      v-tooltip="t('event.event_has_tracked_time')"
                      :size="24"
                      stroke-width="1.5"
                    />
                  </div>
                </div>
              </AppTableTd>
              <AppTableTd>
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <FormWeekPicker
                      size="small"
                      v-model="task.task_week"
                      :invalid="!task.task_week"
                      @change="onTaskChangeForGenerationEvents(task, true)"
                      required
                      teleport
                      :min-date="project?.start_date ? getFirstDayOfWeek(project.start_date) : ''"
                      :max-date="project?.end_date ? getLastDayOfWeek(project.end_date) : ''"
                      :disabled="task.is_deleted"
                    />
                  </div>
                  <div class="flex-shrink-0 ml-2">
                    <AppButton
                      @click.stop.prevent="copyWeekToNextTasks(task, index, false)"
                      size="small"
                      circle
                      light
                      :disabled="task.is_deleted || index + 1 >= tasks.length || !task.task_week"
                      v-tooltip.bottom="t('project.tooltip.copy_next_week')"
                    >
                      <FontIcon name="chevron-down" />
                    </AppButton>
                    <AppButton
                      @click.stop.prevent="copyWeekToNextTasks(task, index, true)"
                      class="ml-1"
                      size="small"
                      circle
                      light
                      :disabled="task.is_deleted || index + 2 >= tasks.length || !task.task_week"
                      v-tooltip.bottom="t('project.tooltip.copy_next_weeks')"
                    >
                      <FontIcon name="chevrons-down" />
                    </AppButton>
                  </div>
                </div>
                <div class="mt-4" v-if="task.collapsed && task.default_frequency">
                  <div v-for="(event, eventIndex) in task.events" :key="event.uid" class="mt-2">
                    <div class="form-wrapper is-small has-icon d-flex align-items-center">
                      <div class="flex-grow-1">
                        <FormWeekPicker
                          size="small"
                          v-model="event.week"
                          :invalid="!event.week"
                          required
                          teleport
                          :min-date="project?.start_date ? getFirstDayOfWeek(project.start_date) : ''"
                          :max-date="project?.end_date ? getLastDayOfWeek(project.end_date) : ''"
                          :disabled="task.is_deleted || event.is_deleted || event.is_invoiced || event.is_done"
                        />
                      </div>
                      <div class="flex-shrink-0 ml-2">
                        <AppButton
                          @click.stop.prevent="copyWeekToNextEvents(task, eventIndex, false)"
                          size="small"
                          circle
                          light
                          :disabled="
                            task.is_deleted || event.is_deleted || eventIndex + 1 >= task.events.length || !event.week
                          "
                          v-tooltip.bottom="t('project.tooltip.copy_next_week')"
                        >
                          <FontIcon name="chevron-down" />
                        </AppButton>
                        <AppButton
                          @click.stop.prevent="copyWeekToNextEvents(task, eventIndex, true)"
                          class="ml-1"
                          size="small"
                          circle
                          light
                          :disabled="
                            task.is_deleted || event.is_deleted || eventIndex + 2 >= task.events.length || !event.week
                          "
                          v-tooltip.bottom="t('project.tooltip.copy_next_weeks')"
                        >
                          <FontIcon name="chevrons-down" />
                        </AppButton>
                      </div>
                    </div>
                  </div>
                  <AppButton
                    v-if="!task.is_deleted"
                    @click.prevent="addBlankEvent(task)"
                    class="mt-2"
                    color="success"
                    size="small"
                    circle
                    light
                    v-tooltip.right="t('common.add')"
                  >
                    <FontIcon name="plus" />
                  </AppButton>
                </div>
              </AppTableTd>
              <AppTableTd>
                <div v-if="task.default_frequency === null" class="form-wrapper is-small has-icon">
                  <VueDatePicker
                    v-if="!task.events[0]"
                    :ui="{ input: 'form-control' }"
                    :placeholder="t('common.select')"
                    disabled
                    six-weeks="center"
                  >
                    <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                  </VueDatePicker>
                  <VueDatePicker
                    v-else
                    :ui="{ input: 'form-control' }"
                    :placeholder="t('common.select')"
                    v-model="task.events[0].deadline"
                    model-type="format"
                    format="yyyy-MM-dd"
                    :enable-time-picker="false"
                    :month-change-on-scroll="false"
                    auto-apply
                    text-input
                    :min-date="project?.start_date ? getFirstDayOfWeek(project.start_date) : ''"
                    :locale="locale"
                    :week-num-name="t('common.week_short')"
                    :clearable="false"
                    :disabled="task.is_deleted"
                    teleport
                    week-numbers="iso"
                    six-weeks="center"
                  >
                    <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                  </VueDatePicker>
                </div>
                <div style="height: 24px" v-else />
                <div class="mt-4" v-if="task.collapsed && task.default_frequency">
                  <div v-for="event in task.events" :key="event.uid" class="mt-2">
                    <div class="form-wrapper is-small has-icon">
                      <VueDatePicker
                        :ui="{ input: 'form-control' }"
                        :placeholder="t('common.select')"
                        v-model="event.deadline"
                        model-type="format"
                        format="yyyy-MM-dd"
                        :enable-time-picker="false"
                        :month-change-on-scroll="false"
                        auto-apply
                        text-input
                        :min-date="project?.start_date ? getFirstDayOfWeek(project.start_date) : ''"
                        :locale="locale"
                        :week-num-name="t('common.week_short')"
                        :clearable="false"
                        :disabled="event.is_deleted"
                        teleport
                        week-numbers="iso"
                        six-weeks="center"
                      >
                        <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                      </VueDatePicker>
                    </div>
                  </div>
                </div>
              </AppTableTd>
              <AppTableTd>
                <FormMinutesDuration
                  size="small"
                  :step="15"
                  :max="5985"
                  v-model="task.default_time_budget"
                  @change="onTaskChangeForGenerationEvents(task, true)"
                  required
                  :disabled="task.is_deleted"
                />
                <div class="mt-4" v-if="task.collapsed && task.default_frequency">
                  <div class="mt-2" v-for="event in task.events" :key="event.uid">
                    <FormMinutesDuration
                      size="small"
                      :step="15"
                      :max="5985"
                      v-model.number="event.scheduled_time"
                      required
                      :disabled="task.is_deleted || event.is_deleted || event.is_invoiced || event.is_done"
                    />
                  </div>
                </div>
              </AppTableTd>
              <AppTableTd>
                <div class="form-wrapper is-small">
                  <select
                    @change="onTaskChangeForGenerationEvents(task, true)"
                    class="form-control"
                    v-model="task.user_uuid"
                    required
                    :disabled="task.is_deleted"
                    :class="{ 'is-invalid': !task.user_uuid }"
                  >
                    <option :value="null" hidden disabled v-text="t('common.select')" />
                    <option
                      v-for="(option, optionIndex) in teamMembers"
                      :key="optionIndex"
                      :value="option.uuid"
                      :disabled="!option.is_active"
                    >
                      {{ option.name + (option.is_active ? '' : ` (${t('common.deleted')})`) }}
                    </option>
                  </select>
                </div>
                <div class="mt-4" v-if="task.collapsed && task.default_frequency">
                  <div v-for="event in task.events" :key="event.uid" class="form-wrapper is-small mt-2">
                    <select
                      class="form-control"
                      v-model="event.user_uuid"
                      required
                      :disabled="task.is_deleted || event.is_deleted || event.is_invoiced || event.is_done"
                      :class="{ 'is-invalid': !event.user_uuid }"
                    >
                      <option :value="null" hidden disabled v-text="t('common.select')" />
                      <option
                        v-for="(option, optionIndex) in teamMembers"
                        :key="optionIndex"
                        :value="option.uuid"
                        :disabled="!option.is_active"
                      >
                        {{ option.name + (option.is_active ? '' : ` (${t('common.deleted')})`) }}
                      </option>
                    </select>
                  </div>
                </div>
              </AppTableTd>
              <AppTableTd>
                <FormSwitch
                  group-class="mb-0"
                  :id="`custom_price-${task.uid}`"
                  v-model="task.custom_price"
                  :disabled="task.is_deleted"
                />
              </AppTableTd>
              <AppTableTd>
                <div class="form-wrapper is-small">
                  <select
                    class="form-control"
                    v-model="task.custom_price_type"
                    :disabled="task.is_deleted || !task.custom_price"
                    :class="{ 'is-invalid': task.custom_price && !task.custom_price_type }"
                  >
                    <option :value="null" hidden disabled v-text="t('common.select')" />
                    <option :value="ProjectTaskPriceType.FIXED" v-text="t('common.fixed')" />
                    <option :value="ProjectTaskPriceType.HOURLY_DISCOUNT" v-text="t('common.hourly')" />
                  </select>
                </div>
              </AppTableTd>
              <AppTableTd>
                <div class="form-wrapper is-small" :class="{ 'has-icon': task.custom_price_type }">
                  <input
                    type="number"
                    v-model.number="task.custom_price_value"
                    class="form-control"
                    :disabled="task.is_deleted || !task.custom_price"
                    size="5"
                    :min="0"
                    :max="task.custom_price_type === ProjectTaskPriceType.HOURLY_DISCOUNT ? 100 : 9999999"
                    :class="{ 'is-invalid': task.custom_price && task.custom_price_value?.toString() === '' }"
                    placeholder="0"
                  />
                  <i
                    v-if="task.custom_price_type === ProjectTaskPriceType.HOURLY_DISCOUNT"
                    class="form-icon ti ti-percentage"
                  />
                  <i
                    v-if="task.custom_price_type === ProjectTaskPriceType.FIXED"
                    class="form-icon ti ti-currency-krone-swedish"
                  />
                </div>
              </AppTableTd>
              <AppTableTd nowrap>
                <div class="text-right">
                  <AppButton
                    v-if="task.default_frequency"
                    @click.stop.prevent="task.collapsed = !task.collapsed"
                    size="small"
                    light
                    circle
                    :disabled="task.loading"
                    v-tooltip.bottom="t(`project.tooltip.${task.collapsed ? 'hide_events' : 'show_events'}`)"
                  >
                    <FontIcon :name="task.collapsed ? 'chevron-up' : 'chevron-down'" />
                  </AppButton>
                  <AppButton
                    v-if="!task.is_deleted"
                    v-tooltip.left="t('task.tooltip.destroy', { name: task.name || t('common.noname') })"
                    @click.stop.prevent="onTaskDelete(task)"
                    class="ml-2"
                    color="danger"
                    size="small"
                    light
                    circle
                    :disabled="task.loading"
                  >
                    <FontIcon name="trash" />
                  </AppButton>
                  <AppButton
                    v-else
                    v-tooltip.left="t('task.tooltip.restore', { name: task.name || t('common.noname') })"
                    @click.stop.prevent="onTaskRestore(task)"
                    class="ml-2"
                    color="success"
                    size="small"
                    light
                    circle
                    :disabled="task.loading"
                  >
                    <FontIcon name="restore" />
                  </AppButton>
                </div>
                <div class="mt-4" v-if="task.collapsed && task.default_frequency">
                  <div v-for="event in task.events" :key="event.uid" class="mt-2 text-right">
                    <AppButton
                      v-if="!task.is_deleted && !event.is_deleted"
                      v-tooltip.left="t('common.delete')"
                      @click.stop.prevent="onTaskEventDelete(task, event)"
                      color="danger"
                      size="small"
                      light
                      circle
                      :disabled="event.has_tracked_time || event.is_done || event.is_invoiced"
                    >
                      <FontIcon name="trash" />
                    </AppButton>
                    <AppButton
                      v-if="event.is_deleted && !task.is_deleted"
                      v-tooltip.left="t('common.restore')"
                      @click.stop.prevent="event.is_deleted = false"
                      color="success"
                      size="small"
                      light
                      circle
                    >
                      <FontIcon name="restore" />
                    </AppButton>
                  </div>
                </div>
              </AppTableTd>
            </AppTableTr>
          </template>
        </draggable>
      </AppTable>
      <AppBox class="mt-1">
        <AppBoxBody>
          <div class="d-flex align-items-center flex-nowrap">
            <div class="form-group mb-0">
              <input id="add_label_on_tasks" type="checkbox" class="form-check" v-model="isSubLabelEnabled" />
              <label for="add_label_on_tasks" class="form-label" v-text="t('project.tasks_table.add_label_on_tasks')" />
            </div>
            <AppButton
              @click.prevent="addBlankTask"
              class="ml-auto"
              color="success"
              circle
              light
              v-tooltip.left="t('common.add')"
            >
              <FontIcon name="plus" />
            </AppButton>
          </div>
        </AppBoxBody>
        <AppBoxBody v-if="projectShouldBeReactivated">
          <AppAlert type="danger">
            {{ t('project.messages.project_should_be_reactivated_tasks.text') }}
          </AppAlert>
        </AppBoxBody>
      </AppBox>
      <div class="mt-3 d-flex flex-nowrap">
        <AppButton
          @click.prevent="emit('change-step', ProjectStep.Team)"
          class="mr-2"
          light
          :disabled="saveLoader.isLoading.value || nextLoader.isLoading.value"
        >
          <FontIcon name="chevron-left" />
          {{ t('common.back') }}
        </AppButton>
        <AppButton
          class="ml-auto mr-2"
          @click="submitMode = 'update'"
          color="success"
          :loading="saveLoader.isLoading.value"
          :disabled="tasks.length === 0 || nextLoader.isLoading.value"
        >
          <FontIcon name="device-floppy" />
          {{ t('common.update') }}
        </AppButton>
        <AppButton
          @click="submitMode = 'next'"
          color="secondary"
          :loading="nextLoader.isLoading.value"
          :disabled="tasks.length === 0 || saveLoader.isLoading.value"
        >
          {{ t('common.next') }}
          <FontIcon name="chevron-right" />
        </AppButton>
      </div>
    </form>
  </template>
</template>

<style scoped lang="scss">
.tasks-table {
  tr td {
    vertical-align: top;
  }
}

.task-status-icons {
  display: flex;
  align-items: center;
  justify-content: end;
}
.is-expanded {
  border: 2px solid var(--color-neutral-400-hex);
}
</style>
