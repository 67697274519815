<script setup lang="ts">
import { computed, onMounted, provide, ref, Ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useRouteParams } from '@vueuse/router';
import { useLocalStorage, useTitle } from '@vueuse/core';
import { useModal } from 'vue-final-modal';
import { Dropdown } from 'floating-vue';
import { klona } from 'klona';
import { DateTime } from 'luxon';
import {
  AppBox,
  AppBoxBody,
  AppButton,
  AppCollapse,
  AppLoader,
  ConfirmModal,
  FontIcon,
  FormCheck,
  FormInput,
  TimeEntryDropdownOptions,
  TimelineActivities,
  TimelineClient,
  TimelineProject,
  TimelineWeek,
  TimeSheetTable,
  UserDeadlines,
  UserPortalStats,
  UserTodos,
} from '@/components';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import useDate from '@/composables/useDate';
import useAuthStore from '@/store/AuthStore';
import { IEventTimelineWeek } from '@/types/Event';
import { ActivityModalProps } from '@/types/Activity';
import { ProjectTaskModalProps } from '@/types/ProjectTask';
import { IUserResource } from '@/types/User';
import { ConfirmDialogConfirmParams, SystemId } from '@/types/Common';
import useInvoice from '@/composables/useInvoice';
import useTimeReportModal from '@/composables/useTimeReportModal';
import { AddTrackedTimeEmitParams } from '@/types/TimeReportModals';

const { getCurrentYearAndWeek } = useDate();
const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();
const actionLoader = useLoader();
const weekStartLoader = useLoader();
const updateTimelineLoader = useLoader({ useProgress: true });
const weekEndLoader = useLoader();
const { authenticatedUser, hasSubscriptionToSystem } = useAuthStore();
const route = useRoute();
useTitle(computed(() => t('dashboard.index.title')));

const filterSearch = ref('');
const filterHideDone = useLocalStorage<number>('we_plan_dashboard_filter_hide_done', 0);
const filterShowAll = useLocalStorage<number>('we_plan_dashboard_filter_show_all', 0);
const filterPrioritised = useLocalStorage<number>('we_plan_dashboard_filter_prioritised', 0);
const filterHasDeadline = useLocalStorage<number>('we_plan_dashboard_filter_has_deadline', 0);
const filterDeadlineThisWeekOrEarlier = useLocalStorage<number>('we_plan_dashboard_filter_this_week_or_earlier', 0);

const userUuid = useRouteParams('uuid', authenticatedUser.uuid);

const isPersonal = computed(() => !route.params.uuid);

const userDeadlinesComponent = shallowRef<InstanceType<typeof UserDeadlines>>();

const loadingToDone = ref<number[]>([]);
const loadingToReportResidualTime = ref<number[]>([]);
const loadingEventsToPrioritise = ref<number[]>([]);

const collapsedProjects = ref<string[]>([]);
const weekStart = ref(-1);
const weekEnd = ref(0);
const timeline = ref<Record<string, IEventTimelineWeek>>({});

const isAcceptableSearchFilling = computed(() => filterSearch.value.length >= 2);

type ExpandedSections = {
  weeks: string[];
  clients: string[];
  timeSheets: string[];
  activities: string[];
};
const expanded = ref<ExpandedSections>({
  weeks: [],
  clients: [],
  timeSheets: [],
  activities: [],
});
const expandedInSearchMode = ref<ExpandedSections>({
  weeks: [],
  clients: [],
  timeSheets: [],
  activities: [],
});

const isExpanded = (key: keyof typeof expanded.value, value: string): boolean => {
  if (isAcceptableSearchFilling.value) {
    return expandedInSearchMode.value[key].includes(value);
  }
  return expanded.value[key].includes(value);
};

const expand = (key: keyof typeof expanded.value, value: string): void => {
  if (isExpanded(key, value)) return;
  if (isAcceptableSearchFilling.value) {
    expandedInSearchMode.value[key] = [...expandedInSearchMode.value[key], value];
  } else {
    expanded.value[key] = [...expanded.value[key], value];
  }
};

const collapse = (key: keyof typeof expanded.value, value: string): void => {
  if (!isExpanded(key, value)) return;
  if (isAcceptableSearchFilling.value) {
    expandedInSearchMode.value[key] = expandedInSearchMode.value[key].filter((item) => item !== value);
  } else {
    expanded.value[key] = expanded.value[key].filter((item) => item !== value);
  }
};

const toggle = (key: keyof typeof expanded.value, value: string): void => {
  if (isExpanded(key, value)) {
    collapse(key, value);
  } else {
    expand(key, value);
  }
};

const { openFetchNotInvoicedModal } = useInvoice();

const { openProjectTaskCreateModal, openProjectTaskEditModal, openActivityTaskCreateModal, openActivityTaskEditModal } =
  useTimeReportModal();

async function onPrioritise(id: number, isPrioritised: boolean, weekToUpdate: string) {
  try {
    loadingEventsToPrioritise.value.push(id);
    await api.events.prioritiseBulk({
      ids: [id],
      is_prioritised: isPrioritised,
    });
    await updateTimeline([+weekToUpdate]);
  } catch (error) {
    console.error(error);
  } finally {
    loadingEventsToPrioritise.value = loadingEventsToPrioritise.value.filter((item) => item !== id);
  }
}

function onProjectTaskCreate(props: ProjectTaskModalProps) {
  openProjectTaskCreateModal(props, {
    onCreated({ affectedWeeks, close }) {
      updateTimeline(affectedWeeks);
      userDeadlinesComponent.value?.getDeadlines();
      close();
    },
  });
}

function onProjectTaskEdit(id: number) {
  openProjectTaskEditModal(
    { id },
    {
      onUpdated({ affectedWeeks, close }) {
        updateTimeline(affectedWeeks);
        userDeadlinesComponent.value?.getDeadlines();
        close();
      },
      onSplit({ affectedWeeks, close }) {
        updateTimeline(affectedWeeks);
        userDeadlinesComponent.value?.getDeadlines();
        close();
      },
      onDeleted({ affectedWeeks, close }) {
        updateTimeline(affectedWeeks);
        userDeadlinesComponent.value?.getDeadlines();
        close();
      },
    },
  );
}

function onActivityCreate(props: ActivityModalProps) {
  openActivityTaskCreateModal(props, {
    onCreated({ affectedWeeks, close }) {
      updateTimeline(affectedWeeks);
      close();
    },
  });
}

function onActivityEdit(id: number) {
  openActivityTaskEditModal(
    { id },
    {
      onUpdated({ affectedWeeks, close }) {
        updateTimeline(affectedWeeks);
        close();
      },
      onSplit({ affectedWeeks, close }) {
        updateTimeline(affectedWeeks);
        close();
      },
      onDeleted({ affectedWeeks, close }) {
        updateTimeline(affectedWeeks);
        close();
      },
    },
  );
}

async function onDone(idToDone: number, done: boolean, week: string) {
  try {
    actionLoader.start();
    loadingToDone.value.push(idToDone);
    await api.events.done(idToDone, { done });
    await updateTimeline([+week]);
  } catch (error) {
    console.error(error);
  } finally {
    loadingToDone.value = loadingToDone.value.filter((id) => id !== idToDone);
    actionLoader.finish();
  }
}

const user = ref<IUserResource>();

async function getUser(uuid: string) {
  try {
    const response = await api.users.get(uuid);
    user.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function onReportResidualTime(idToDone: number, week: string) {
  try {
    actionLoader.start();
    loadingToReportResidualTime.value.push(idToDone);
    await api.events.done(idToDone, { done: true, report_residual_time: true });
    await updateTimeline([+week]);
  } catch (error) {
    console.error(error);
  } finally {
    loadingToReportResidualTime.value = loadingToReportResidualTime.value.filter((id) => id !== idToDone);
    actionLoader.finish();
  }
}

function toggleProject(uniqueId: string) {
  if (collapsedProjects.value.includes(uniqueId)) {
    collapsedProjects.value = collapsedProjects.value.filter((item) => item !== uniqueId);
  } else {
    collapsedProjects.value.push(uniqueId);
  }
}

type GetTimelineParams = {
  withRange?: boolean;
};

async function getTimeline(params: GetTimelineParams = {}) {
  const withRange = params?.withRange ?? true;
  try {
    const searchParams = new URLSearchParams();
    if (withRange) {
      searchParams.append('weekStart', weekStart.value.toString());
      searchParams.append('weekEnd', weekEnd.value.toString());
    }
    const response = await api.users.events.timeline(userUuid.value, { searchParams });
    timeline.value = response.data;
    if (!withRange) {
      weekStart.value = -(Object.keys(response.data).length - 1);
    }
  } catch (error) {
    console.error(error);
  }
}

async function updateTimeline(weeks: number[]) {
  updateTimelineLoader.start();
  try {
    const searchParams = new URLSearchParams();
    weeks.forEach((week) => {
      if (week.toString() in timeline.value) {
        searchParams.append('weeks[]', week.toString());
      }
    });
    const { data } = await api.users.events.timeline(userUuid.value, { searchParams });
    Object.keys(data).forEach((week) => {
      timeline.value[week] = data[week];
    });
  } catch (error) {
    console.error(error);
  } finally {
    updateTimelineLoader.finish();
  }
}

async function loadStartWeek() {
  try {
    weekStartLoader.start();
    const week = weekStart.value - 1;
    const searchParams = new URLSearchParams();
    searchParams.append('weekStart', week.toString());
    searchParams.append('weekEnd', week.toString());
    const response = await api.users.events.timeline(userUuid.value, { searchParams });
    weekStart.value = week;
    timeline.value = { ...response.data, ...timeline.value };
  } catch (error) {
    console.error(error);
  } finally {
    weekStartLoader.finish();
  }
}

async function loadEndWeek() {
  try {
    weekEndLoader.start();
    const week = weekEnd.value + 1;
    const searchParams = new URLSearchParams();
    searchParams.append('weekStart', week.toString());
    searchParams.append('weekEnd', week.toString());
    const response = await api.users.events.timeline(userUuid.value, { searchParams });
    weekEnd.value = week;
    timeline.value = { ...timeline.value, ...response.data };
  } catch (error) {
    console.error(error);
  } finally {
    weekEndLoader.finish();
  }
}

function onMarkWeekAsDone(week: string) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('event.confirm.mark_week_as_done.title'),
      message: t('event.confirm.mark_week_as_done.text'),
      confirmLabel: t('common.yes'),
      confirmColor: 'success',
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          const searchParams = new URLSearchParams();
          searchParams.append('week', week);
          searchParams.append('user_uuid', authenticatedUser.uuid);
          const { affectedWeeks } = await api.events.moveWeekForward({ searchParams });
          await Promise.all([updateTimeline(affectedWeeks), close()]);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

async function onDropEvents(events: number[], toWeek: string) {
  try {
    const { affectedWeeks } = await api.events.move({ ids: events, week: Number(toWeek) });
    await updateTimeline(affectedWeeks);
  } catch (error) {
    console.error(error);
  }
}

onMounted(async () => {
  loader.start();
  if (route.params.uuid && typeof route.params.uuid === 'string') {
    await getUser(route.params.uuid);
  }
  await getTimeline({ withRange: false });
  expand('weeks', getCurrentYearAndWeek().toString());
  expand('timeSheets', getCurrentYearAndWeek().toString());
  loader.finish();
});

provide<Ref<number>>('hide_done', filterHideDone);
provide<Ref<number>>('show_all_tasks', filterShowAll);

watch(filterSearch, (value) => {
  if (value.length < 2) return;
  expandedInSearchMode.value.weeks = [];
  expandedInSearchMode.value.clients = [];
  expandedInSearchMode.value.timeSheets = [];
  expandedInSearchMode.value.activities = [];

  filteredTimeline.value.forEach((timelineWeek) => {
    if (timelineWeek.tasks.events.length > 0 || timelineWeek.activities.events.length > 0) {
      expand('weeks', timelineWeek.week);
      expand('timeSheets', timelineWeek.week);
    }
    if (timelineWeek.tasks.events.length > 0) {
      timelineWeek.tasks.events.forEach((client) => {
        expand('clients', client.uuid + timelineWeek.week);
      });
    }
    if (timelineWeek.activities.events.length > 0) {
      expand('activities', timelineWeek.week + 'activity');
    }
  });
});

const filteredTimeline = computed(() => {
  const clonedTimeline = klona(timeline.value);
  const results: IEventTimelineWeek[] = Object.keys(clonedTimeline).reduce(
    (acc, weekNumber) => [...acc, clonedTimeline[weekNumber]],
    [] as IEventTimelineWeek[],
  );

  results.forEach((timelineWeek) => {
    if (filterHideDone.value) {
      timelineWeek.activities.events = timelineWeek.activities.events.filter((event) => event.done_at === null);
      timelineWeek.tasks.events.forEach((client) => {
        client.projects.forEach((project) => {
          project.tasks = project.tasks.filter((task) => task.done_at === null);
        });
      });
    }
    if (filterPrioritised.value) {
      results.forEach((timelineWeek) => {
        timelineWeek.activities.events = timelineWeek.activities.events.filter((event) => event.is_prioritised);
        timelineWeek.tasks.events.forEach((client) => {
          client.projects.forEach((project) => {
            project.tasks = project.tasks.filter((task) => task.is_prioritised);
          });
        });
      });
    }
    if (filterDeadlineThisWeekOrEarlier.value) {
      timelineWeek.tasks.events.forEach((client) => {
        client.projects.forEach((project) => {
          project.tasks = project.tasks.filter((task) => {
            if (task.deadline === null) {
              return false;
            }
            const deadlineDate = DateTime.fromISO(task.deadline);
            return deadlineDate.weekNumber <= DateTime.now().weekNumber && deadlineDate.year <= DateTime.now().year;
          });
        });
      });
    } else if (filterHasDeadline.value) {
      timelineWeek.tasks.events.forEach((client) => {
        client.projects.forEach((project) => {
          project.tasks = project.tasks.filter((task) => task.deadline);
        });
      });
    }

    if (isAcceptableSearchFilling.value) {
      const q = filterSearch.value.toLowerCase().trim();
      timelineWeek.tasks.events = timelineWeek.tasks.events.filter((client) => {
        return (
          client.name.toLowerCase().includes(q) ||
          client.projects.some((p) => p.name.toLowerCase().includes(q)) ||
          client.projects.some((p) => p.team_members.some((tm) => tm.name.toLowerCase().includes(q))) ||
          client.projects.some((p) => p.tasks.some((t) => t.name.toLowerCase().includes(q))) ||
          client.active_services.some((s) => s.name.toLowerCase().includes(q))
        );
      });
      timelineWeek.activities.events = timelineWeek.activities.events.filter((event) => {
        return event.name.toLowerCase().includes(q);
      });
      timelineWeek.time_sheet.events = timelineWeek.time_sheet.events.filter((event) => {
        return event.name.toLowerCase().includes(q);
      });
    } else {
      if (!filterShowAll.value) {
        results.forEach((timelineWeek) => {
          timelineWeek.time_sheet.events = timelineWeek.time_sheet.events.filter((event) => event.total > 0);
        });
      }
    }
  });

  return results;
});

function onFetchNotInvoiced(uuid: string, selectedProjects: number[]) {
  openFetchNotInvoicedModal(
    {
      client: uuid,
      selectedProjects,
    },
    {
      async onFetched() {
        //
      },
    },
  );
}

function onProjectTaskAddTrackedTime(params: AddTrackedTimeEmitParams) {
  openProjectTaskEditModal(
    {
      id: params.id,
      addTrackedTimeOnOpen:
        typeof params.date === 'string' && typeof params.time === 'number'
          ? {
              date: params.date,
              time: params.time,
            }
          : undefined,
    },
    {
      onUpdated({ affectedWeeks, close }) {
        updateTimeline(affectedWeeks);
        userDeadlinesComponent.value?.getDeadlines();
        close();
      },
      onSplit({ affectedWeeks, close }) {
        updateTimeline(affectedWeeks);
        userDeadlinesComponent.value?.getDeadlines();
        close();
      },
      onDeleted({ affectedWeeks, close }) {
        updateTimeline(affectedWeeks);
        userDeadlinesComponent.value?.getDeadlines();
        close();
      },
    },
  );
}

function onActivityAddTrackedTime(params: AddTrackedTimeEmitParams) {
  openActivityTaskEditModal(
    {
      id: params.id,
      addTrackedTimeOnOpen:
        typeof params.date === 'string' && typeof params.time === 'number'
          ? {
              date: params.date,
              time: params.time,
            }
          : undefined,
    },
    {
      onUpdated({ affectedWeeks, close }) {
        updateTimeline(affectedWeeks);
        close();
      },
      onSplit({ affectedWeeks, close }) {
        updateTimeline(affectedWeeks);
        close();
      },
      onDeleted({ affectedWeeks, close }) {
        updateTimeline(affectedWeeks);
        close();
      },
    },
  );
}
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-9">
        <div v-if="loader.isLoading.value" class="text-center">
          <AppLoader size="large" />
        </div>
        <div v-else>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h2 v-if="isPersonal" class="mb-0" v-text="t('dashboard.index.title')" />
            <h2 v-else class="mb-0" v-text="user?.name" />
            <Dropdown placement="bottom-end" :distance="10">
              <AppButton color="secondary">
                <FontIcon name="plus" />
                {{ t('dashboard.index.create') }}
                <FontIcon name="chevron-down" />
              </AppButton>
              <template #popper="{ hide }">
                <TimeEntryDropdownOptions
                  @on-absence="onActivityCreate($event)"
                  @on-project-task="onProjectTaskCreate($event)"
                  @on-internal="onActivityCreate($event)"
                  :hide="hide"
                  new-customer-link
                />
              </template>
            </Dropdown>
          </div>
          <!-- Filters -->
          <AppCollapse opened class="my-3" :title="t('dashboard.index.filters')">
            <template #middleHeader>
              <FormInput
                group-class="ml-4"
                group-styles="width: 500px;"
                type="search"
                v-model="filterSearch"
                id="filter_search"
                :placeholder="t('dashboard.filters.search_placeholder')"
                icon="search"
              />
            </template>
            <div class="pt-3 d-flex gap-4">
              <FormCheck group-class="mb-0" v-model="filterHideDone" :label="t('dashboard.filters.hide_done')">
                <template #label="{ label }">
                  {{ label }}
                  <FontIcon style="color: var(--color-success-500-hex)" name="check" />
                </template>
              </FormCheck>
              <FormCheck group-class="mb-0" v-model="filterShowAll" :label="t('dashboard.filters.show_all')">
                <template #label="{ label }">
                  {{ label }}
                  <FontIcon style="color: var(--color-blue-500-hex)" name="list-details" />
                </template>
              </FormCheck>
              <FormCheck group-class="mb-0" v-model="filterHasDeadline" :label="t('dashboard.filters.has_deadline')">
                <template #label="{ label }">
                  {{ label }}
                  <FontIcon style="color: var(--color-orange-400-hex)" name="alert-triangle-filled" />
                </template>
              </FormCheck>
              <FormCheck
                group-class="mb-0"
                v-model="filterDeadlineThisWeekOrEarlier"
                :label="t('dashboard.filters.this_week_or_earlier')"
              >
                <template #label="{ label }">
                  {{ label }}
                  <FontIcon style="color: var(--color-danger-500-hex)" name="alert-triangle-filled" />
                </template>
              </FormCheck>
              <FormCheck group-class="mb-0" v-model="filterPrioritised" :label="t('dashboard.filters.prioritised')">
                <template #label="{ label }">
                  {{ label }}
                  <FontIcon style="color: var(--color-yellow-400-hex)" name="star-filled" />
                </template>
              </FormCheck>
            </div>
          </AppCollapse>
          <!-- /Filters -->
          <!-- Load start week start -->
          <AppButton
            class="mb-3"
            light
            size="small"
            @click.prevent="loadStartWeek"
            :loading="weekStartLoader.isLoading.value"
            :disabled="weekStartLoader.isLoading.value || weekEndLoader.isLoading.value"
          >
            {{ t('dashboard.buttons.load_prev_week') }}
            <FontIcon name="chevrons-up" />
          </AppButton>
          <!-- Load start week end -->

          <!-- Weeks start -->
          <TransitionGroup name="list" tag="div">
            <div style="margin-bottom: 5px" v-for="timelineWeek in filteredTimeline" :key="timelineWeek.week">
              <TimelineWeek
                :expanded="isExpanded('weeks', timelineWeek.week)"
                :data="timelineWeek"
                :week-number="timelineWeek.week"
                @toggle="toggle('weeks', $event)"
                @mark-week-as-done="onMarkWeekAsDone($event)"
                :is-current="getCurrentYearAndWeek() === +timelineWeek.week"
                :is-completed="timelineWeek.is_completed"
                @drop-events="onDropEvents"
              >
                <div v-if="isExpanded('weeks', timelineWeek.week)">
                  <!-- Project tasks -->
                  <div
                    style="margin-top: 1px"
                    v-for="client in timelineWeek.tasks.events.filter((client) =>
                      client.projects.some((project) => project.tasks.length),
                    )"
                    :key="client.uuid + timelineWeek.week"
                  >
                    <TimelineClient
                      :expanded="isExpanded('clients', client.uuid + timelineWeek.week)"
                      :data="client"
                      :user-working-time-minutes="timelineWeek.user_working_time_minutes"
                      :week-number="timelineWeek.week"
                      @toggle="toggle('clients', $event + timelineWeek.week)"
                      @fetch-not-invoiced="onFetchNotInvoiced($event, [])"
                    />
                    <template v-if="isExpanded('clients', client.uuid + timelineWeek.week)">
                      <div style="margin-top: 1px" v-for="project in client.projects" :key="project.id">
                        <TimelineProject
                          :client-uuid="client.uuid"
                          :user-uuid="userUuid"
                          :data="project"
                          :week-number="timelineWeek.week"
                          :collapsed="collapsedProjects.includes(project.id + client.uuid + timelineWeek.week)"
                          :loading-to-done="loadingToDone"
                          :loading-to-report-residual-time="loadingToReportResidualTime"
                          :loading-events-to-prioritise="loadingEventsToPrioritise"
                          @toggle="toggleProject($event + client.uuid + timelineWeek.week)"
                          @edit="onProjectTaskEdit($event)"
                          @prioritise="(id, isPrioritised) => onPrioritise(id, isPrioritised, timelineWeek.week)"
                          @done="(id, done) => onDone(id, done, timelineWeek.week)"
                          @report-residual-time="onReportResidualTime($event, timelineWeek.week)"
                          @fetch-not-invoiced="
                            (clientUuid, selectedProjects) => onFetchNotInvoiced(clientUuid, selectedProjects)
                          "
                        />
                      </div>
                    </template>
                  </div>
                  <!-- Internal activities -->
                  <TimelineActivities
                    :expanded="isExpanded('activities', timelineWeek.week + 'activity')"
                    :data="timelineWeek.activities"
                    :week-number="timelineWeek.week"
                    :loading-to-done="loadingToDone"
                    :loading-to-report-residual-time="loadingToReportResidualTime"
                    :loading-events-to-prioritise="loadingEventsToPrioritise"
                    @toggle="toggle('activities', $event + 'activity')"
                    @edit="onActivityEdit($event)"
                    @prioritise="(id, isPrioritised) => onPrioritise(id, isPrioritised, timelineWeek.week)"
                    @done="(id, done) => onDone(id, done, timelineWeek.week)"
                    @report-residual-time="onReportResidualTime($event, timelineWeek.week)"
                  />

                  <!-- TimeSheet -->
                  <div style="border-top: 1px solid var(--color-neutral-200-hex)">
                    <TimeSheetTable
                      :expanded="isExpanded('timeSheets', timelineWeek.week)"
                      :data="timelineWeek.time_sheet"
                      :week="timelineWeek.week"
                      :user-uuid="userUuid"
                      @toggle="toggle('timeSheets', $event)"
                      @activity-create="onActivityCreate($event)"
                      @project-task-create="onProjectTaskCreate($event)"
                      @activity-edit="onActivityEdit"
                      @project-task-edit="onProjectTaskEdit"
                      @project-task-add-tracked-time="onProjectTaskAddTrackedTime"
                      @activity-add-tracked-time="onActivityAddTrackedTime"
                      @updated="updateTimeline([$event])"
                    />
                  </div>
                </div>
              </TimelineWeek>
            </div>
          </TransitionGroup>
          <!-- Weeks end -->

          <!-- Load end week start -->
          <AppButton
            class="mt-2"
            light
            size="small"
            @click.prevent="loadEndWeek"
            :loading="weekEndLoader.isLoading.value"
            :disabled="weekEndLoader.isLoading.value || weekStartLoader.isLoading.value"
          >
            {{ t('dashboard.buttons.load_next_week') }}
            <FontIcon name="chevrons-down" />
          </AppButton>
          <!-- Load end week end -->
        </div>

        <!-- ToDos -->
        <div class="row mt-4">
          <div class="col-md-6">
            <UserTodos type="todos" :title="t('user.todos.todos.title')" :user-uuid="userUuid" />
          </div>
          <div class="col-md-6">
            <UserTodos type="infos" :title="t('user.todos.informations.title')" :user-uuid="userUuid" />
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <UserPortalStats v-if="hasSubscriptionToSystem(SystemId.MY_AUDIT)" :user-uuid="userUuid" class="mb-3" />
        <UserDeadlines
          ref="userDeadlinesComponent"
          :user-uuid="userUuid"
          :week-number="getCurrentYearAndWeek()"
          @updated="({ affectedWeeks }) => updateTimeline(affectedWeeks)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.draggable {
  cursor: pointer;
  user-select: none;

  &.dragging {
    opacity: 0.25;
    background-color: white;
  }
}

.droppable {
  &.over {
    background-color: var(--color-neutral-100);
  }
}
</style>
